
 #nprogress .bar {
       background: #fff;
       z-index: 9000;
       width: 100%;
       height: 6px;
       top: 0px;
       left: 0px;
 }

 #nprogress .spinner {
      display: none;
 }

 #nprogress .peg {
     display: none;
 }