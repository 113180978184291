@media only screen and (max-width: 599px){
  nav{
    padding: 0px 16px;
    background-color: white;
    color: rgba(255, 255, 255, 0.87);
  }
  .imgSize{
    max-height: 55px;
    max-width: 50vw;
    margin-left: 0px !important;
  }
  .accountMenu{
    height: 35px;
    width: 35px;
  }

  .div-header{
      margin: 0 16px auto;
      margin-left: 0px !important
  }
}

@media only screen and (min-width: 600px) and (max-width: 959px){
  nav{
    padding: 0px 16px;
    background-color: white;
    color: rgba(255, 255, 255, 0.87);
  }
  .imgSize{
    max-height:  60px;
  }
  .accountMenu{
    height: 40px;
    width: 40px;
  }

  .div-header{
      margin: 0 46px auto;
  }
}

@media only screen and (min-width: 960px){
  nav{
    height: 100px;
    padding: 0px 16px;
    background-color: white;
    color: rgba(255, 255, 255, 0.87);
  }
  .imgSize{
    max-height:  85px;
  }

  .accountMenu{
    height: 45px;
    width: 45px;
  }

  .div-header{
    margin: 0 46px auto;
  }
}

.div-header {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logo-img {
  padding: 4px;
  cursor: pointer;
}

.top-right-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.account_popover .name {
  font-weight: 400;
  color: black;
  line-height: 1.5;
  font-size: 15px;
}

.account_popover .email {
  font-weight: 300;
  color: grey;
  line-height: 1.5;
  font-size: 15px;
}

.account_popover .row_1 {
  padding: 12px 16px;
}

.account_popover MenuItem {
  text-decoration: none;
  line-height: 1.5;
  font-size: 15px;
  display: block;
  font-weight: 400;
  color: #000;
}
