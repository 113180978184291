@media (max-width: 599px) {
  .flex-xs-30,
  .layout-row > .flex-xs-30,
  .layout-xs-row > .flex-xs-30 {
    -webkit-flex: 1 1 30%;
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
}


@media   only screen and (min-width: 960px) and (max-width: 1279px) {
  .flex-md-25,
  .layout-md-row > .flex-md-25,
  .layout-row > .flex-md-25 {
    -webkit-flex: 1 1 25%;
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .checkboxDiv {
    margin: auto auto auto 70px
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .selectiveDiv{
     margin : auto auto auto 35px;
  }
   .selectiveDivParent{
       position: relative;
       left: -40px;
   }
 }

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .selectiveDivParent{
    position: relative;
    left: -90px;
  }
  .searchDiv{
    width: 140px;
  }
  .checkboxDiv{
    margin : auto auto auto 20px;
  }
  .buttonsDiv{
    position: relative;
    left: -60px;
  }
}

@media only screen and (min-width: 1280px) {
  .container {
    display: flex;
    margin: auto;
    width: 75%;
    max-height: 100%;
    color: black;
  }
}

@media only screen and (max-width: 580px) {
  .qrCodeField {
    display: flex;
    flex-direction: column;
    margin-top: 55px;
  }
  .qr-img {
    margin-bottom: 18px;
  }
}

@media only screen and (min-width: 581px) {
  .qrCodeField {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
  }
  .qr-img {
     width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .firmwareUploadColumn {
    display: flex !important;
    flex-direction: column !important;
  }
  .portions {
     width: 100% !important;
  }
  .versionBlock {
     width: calc(100% - 5px);
  }
  .firmwareSelect {
    width: calc(100% - 6px);
    objectFit: contain;
    margin-right: 50px !important;
  }
  .applyAndUploadDiv {
    display: flex;
    flex-direction: column !important;
  }
  .applyAndUploadDiv button {
    margin-block: 5px !important;
  }
}

@media only screen and (min-width: 601px) and (max-width:959px) {
    .firmwareUploadColumn {
        display: flex !important;
        flex-direction: row !important;
      }
      .portions {
        width: 100% !important;
      }
      .versionBlock {
        width: calc(100% - 70px)
      }
      .firmwareSelect {
        width: calc(100% - 80px);
        objectFit: contain
      }

}


@media only screen and (min-width: 960px) {
  .firmwareUploadColumn {
    display: flex !important;
    flex-direction: row !important;
  }
  .portions {
    width: 50% !important;
  }
  .versionBlock {
    width: calc(100% - 60px)
  }
  .firmwareSelect {
    width: calc(100% - 70px);
    objectFit: contain
  }
}

.subBlock {
  display: flex;
  flex-direction: row;
  background: #fff;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1279px) {
  .container {
    display: flex;
    margin: auto;
    width: 90%;
    max-height: 100%;
    color: black;
  }
}

@media only screen and (max-width: 959px) {
  .subBlock {
    display: flex;
    background: #fff;
    flex-direction: column;
  }
}

body {
  background-color: #eff3f2;
}

.subContainer {
  -webkit-box-shadow: 0 7px 0px -6px #777;
  -moz-box-shadow: 0 7px 0px -6px #777;
  box-shadow: 0 7px 0px -6px #777;
  background: #fff;
  margin: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.backArrow {
  margin: 0 6px;
  min-width: 0;
  line-height: 24px;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  color: #4190c7;
}

.portions {
  display: flex;
  height: 100%;
  flex: 50;
  flex-direction: column;
  background: #fff;
}

.selectedBlock {
  border: 1px solid #ccc;
  border-style: dashed;
  margin: 8px;
  padding: 20px;
  font-weight: 300;
  text-align: center;
  min-height: 40px;
}

.selectedBlockExist {
  border: 1px solid #ccc;
  border-style: dashed;
  margin: 8px;
  padding: 20px;
  font-weight: 300;
}

.radioGroup {
  display: flex;
  flex-direction: column;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  margin: 8px;
}

.radioButton {
  box-sizing: border-box;
  display: block;
  margin-bottom: 16px;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
}

.tenantsBlock {
  border: 1px solid rgba(0, 0, 0, 0.12);
  height: 400px;
}
.inputSearchBlock {
  display: flex;
  flex-direction: row;
  flex: 20%;
}

.searchBlock {
  display: flex;
  flex-direction: row;
  flex: 40%;
}

.allTenants {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  min-height: 0px;
  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.bottomDiv {
  flex: 20;
  padding: 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 7px 0px -6px #777;
  -moz-box-shadow: 0 7px 0px -6px #777;
  box-shadow: 0 7px 0px -6px #777;
  background: #fff;
}

.tenantItems {
  display: flex;
  flex: 100;
  flex-direction: row;
}

.removeCircle {
  display: none;
}

@media only screen and (max-width: 599px){
   .searchContainer {
     display: flex;
     flex-direction:column;
   }
}

@media only screen and (min-width: 600px) {
  .searchContainer {
    display:  flex;
    flex-direction: row;
  }
}

@media only screen and (max-width: 959px){
   .selectBlock {
     display: flex;
     flex-direction:column;
   }
}

@media only screen and (min-width: 960px) {
  .selectBlock {
    display:  flex;
    flex-direction: row;
  }
}

@media only screen and (max-width: 999px){
   .colorBlock {
     display: flex;
     flex-direction:column;
     width: 100%;
   }
   .colorSelectBlock {
     display :flex;
     flex: 30%;
     margin: 20px;
   }
}

@media only screen and (min-width: 1000px){
   .colorBlock {
     display: flex;
     flex-direction:row;
     width: 100%;
   }
   .colorSelectBlock {
     display :flex;
     flex: 30%;
   }
}

.ccfField .MuiInputBase-inputAdornedEnd {
    width: 100% !important;
    padding-right:0px !important
}

.ccfField input {
    width: 10px !important
}

.ColorPicker-MuiInput-root{
   width: 70%;
}

.ccf {
    position: relative !important;
}

.ccf div svg {
    position: absolute !important;
    right: 15px !important;
}

.debugLogLevelDiv div input {
   width: 94%;
}

.debugLogLevelDiv div div div button svg{
  font-size: 26px;
  right: 17px !important;
  top: -5px !important
}

.debugLogLevelDiv div div div svg{
    cursor: pointer;
    font-size: 18px;
    margin-bottom: -3px;
    right: -7px !important;
    top: 7px
}

.debugLogLevelDiv div svg {
  position: absolute !important;
  right: 11px !important;
  cursor: pointer !important;
}