#humidity svg text {
    font-size: 25px;
    position: relative;
    top: 50px;
    left: 4px;
    color: rgb(51, 102, 204) !important;
}

.customSwitchButton .MuiSwitch-switchBase {
    width: 60px;
    margin-left: 6px;
    margin-top: 12px;
    height: 35px;
    border-radius: 82px;
}

.customSwitchButton .MuiSwitch-switchBase.Mui-checked {
    width: 60px !important;
    margin-left: 24px !important;
    margin-top: 12px !important;
    height: 35px !important;
    border-radius: 80px !important;
}