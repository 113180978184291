.wesko_lock_image {
  max-height: 100%;
  max-width: 100%;
  opacity: 0.8;
  margin: 36px 0 0 0;
}

.loginComponent .MuiGrid-root.MuiGrid-item.loginFooter{
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-left:-10px;
}

.emailTextField label.MuiInputLabel-root {
    color:rgb(231,87,83) !important;
}

.emailTextField .MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary:before{
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

.emailTextField .MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary:after{
    border-bottom: 2px solid rgb(231,87,83) !important;
}

@media only screen and (max-width: 599px){
  .midThreeComponents{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .listItemAvatar{
    width: 48px;
    height: 48px;
  }
  .listItems{
    width: 100%;
    padding: 0px 0px 20px 0px !important;
  }
  .middleComponent{
    flex-direction: column;
    margin: 0px !important;
  }
  .gatewayImage{
    display: none;
  }
  .logInWidth{
    width: 100%;
  }
  .listComponent{
    width: 100%;
    margin: 0px !important;
  }
  .listItemText{
    font-size: 20px !important;
    line-height: 1.15 !important;
    letter-spacing: 0cm !important;
  }
  .configContent{
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1279px){
  .midThreeComponents{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .listItemAvatar{
    width: 48px;
    height: 48px;
  }
  .listItems{
    width: 60%;
  }
  .middleComponent{
    flex-direction: column;
  }
  .listComponent{
    width: 100%;
    margin: 0px !important;
  }
  .imageSize{
    height: 320px;
    width: 100%;
  }
  .logInWidth{
    width: 60%;
  }
  .configContent{
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1865px){
  .listItemAvatar{
    width: 50px;
    height: 56px;
  }
  .listComponent{
    width: 50%;
  }
  .listItems{
    width: 67%;
  }
  .gatewayImage{
    width: 20%;
    margin-top: 60px;
  }
  .middleComponent{
    height: 75%;
    flex-direction: row;
  }
  .imageSize{
    height: 320px;
    width: 400px;
  }
  .logInBox{
    width: 35%;
    justify-content: end;
  }
  .logInWidth{
    width: 82%;
  }
  .configContent{
    display: flex;
    flex-direction: row;
  }
}

@media only screen and (min-width:1866px){
  .listItemAvatar{
    width: 50px;
    height: 56px;
  }
  .listComponent{
    width: 50%;
  }
  .listItems{
    width: 67%;
  }
  .gatewayImage{
    width: 20%;
    margin-top: 60px;
  }
  .middleComponent{
    height: 86%;
    flex-direction: row;
  }
  .imageSize{
    height: 320px;
    width: 460px;
  }
  .logInBox{
    width: 35%;
    justify-content: end;
  }
  .logInWidth{
    width: 82%;
  }
  .configContent{
    display: flex;
    flex-direction: row;
  }
}

.numberTextFieldSpinners{
    input::-webkit-inner-spin-button, input::-webkit-outer-spin-button {
        -webkit-appearance: none; margin: 0;
    }
}