.fabArrowIcon {
  background: rgb(194, 24, 91);
  color: white;
}

.fabArrowIcon:hover {
  background: rgb(194, 24, 91);
  color: white;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  font-size: inherit;
}

.navIcons {
  color: black;
}

.LockManagerHeader {
  align-items: center;
  margin-top: -18px;
  padding-top: 26px;
  padding-left: 22px;
  color: gray;
}

.greenDot {
  height: 10px;
  margin-left: 3px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

.redDot {
  height: 10px;
  margin-left: 3px;
  width: 10px;
  background-color: rgb(206, 46, 46);
  border-radius: 50%;
  display: inline-block;
}

.header_nav {
  min-height: 64px;
  background: #fff;
}

.versionNumber {
  font-size: 12px;
  color: gray;
  padding-left: 3px;
}

.DashboardSideNavItemDiv.css-15chqrh-MuiButtonBase-root-MuiListItem-root.Mui-selected
  .css-i4bv87-MuiSvgIcon-root {
  color: orange;
}

.DashboardSideNavItemDiv.css-15chqrh-MuiButtonBase-root-MuiListItem-root.Mui-selected
  .css-10hburv-MuiTypography-root {
  color: orange;
}

.sideNavMiniIcon {
  color: gray;
}

.sideNavMiniIcon:hover {
  color: #000000de;
}

@media only screen and (max-width: 600px) {
  .LockManagerHeader {
    align-items: center;
    margin-top: 4px;
    padding-top: 26px;
    padding-left: 22px;
  }
}

@media only screen and (min-width: 960px){
  .LockManagerHeader {
    margin-top: 18px;
  }
}

@media only screen and (max-width: 1279px) {
  .smallDevicesDrawer {
    display: block;
  }
  .largeDevicesDrawer {
    display: none;
  }
  .menuIcon {
    display: block;
  }
  .sideNavigation {
    display: none;
  }
}

@media only screen and (min-width: 1280px) {
  .smallDevicesDrawer {
    display: none;
  }
  .largeDevicesDrawer {
    display: block;
  }
  .menuIcon {
    display: none;
  }
  .sideNavigation {
    display: block;
  }
}
